/*
 * VNCtalk - an enterprise real-time communication solution including chat, video and audio conferencing, screen sharing, voice messaging, file sharing, broadcasts, document collaboration and much more.
 * Copyright (C) 2015-2020 VNC – Virtual Network Consult AG (info@vnc.biz)
 *
 * This program is free software: you can redistribute it and/or modify
 * it under the terms of the GNU Affero General Public License as published by
 * the Free Software Foundation, version 3 of the License.
 *
 * This program is distributed in the hope that it will be useful,
 * but WITHOUT ANY WARRANTY; without even the implied warranty of
 * MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE. See the
 * GNU Affero General Public License for more details.
 *
 * You should have received a copy of the GNU Affero General Public License
 * along with this program. Look for COPYING file in the top folder.
 * If not, see http://www.gnu.org/licenses/.
 */

import { Injectable } from "@angular/core";
import { MiddlewareService } from "app/talk/services/middleware.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "app/environments/environment";
import { Observable } from "rxjs";
import { File } from "./models/file.model";
import { Channel } from "./models/channel.model";
import { Topic } from "./models/topic.model";
import {Comment, CommentResponse} from "./models/comment.model";
import { Invitation } from "./models/invitation.model";
import { Attachment } from "./models/attachment.model";
import { ChannelGroupType } from "../talk/archive-window/archive-window-channels/archive-window-channels.component";
import { TopicGroupType } from "../talk/archive-window/archive-window-topic/archive-window-topic.component";
import {FileGrouping} from "./repository/channel.repository";
import {ConfigService} from "../config.service";
import {CommonUtil} from "../talk/utils/common.util";
import {filter, map} from "rxjs/operators";

@Injectable()
export class ChannelService {
  // public userJID$ = new BehaviorSubject<string>("");
  private isCordovaOrElectron = environment.isCordova || environment.isElectron;
  // public keyword$ = new Subject<string>();
  constructor(private middlewareService: MiddlewareService, private configService: ConfigService, private http: HttpClient) {

  }

  takePhoto(): Promise<string> {
    return new Promise((resolve, reject) => {
      cordova.plugins.photos.takePhoto(
        (photoUrl: string) => {
          resolve(photoUrl);
        },
        (error: any) => {
          reject(error);
        }
      );
    });
  }

  createChannel(body, participants) {
    let data = { "channel" : body, participants };
    return this.middlewareService.postTest(`api/channels/create-channel`, true, data);
  }

  createSubchannel(body, participants) {
    let data = { "channel" : body, participants };
    return this.middlewareService.post(`api/channels/create-channel`, true, data);
  }

  getAuthorByChannel(channelId) {
    if (channelId) {
      return this.middlewareService.get(`api/channels/authors/${channelId}`, true);
    }
  }


  getUsersForInvitation() {
    return this.http.get("api/channel-users/getUsers");
  }

  getChannelsFromMS(offset?: number, limit?: number, getOnlyPublic = false, getOnlyPrivate = false, includeArchived = false, getOnlySubscribed = false, getOnlyFavorites = false, getOnlyMyChannels = false, userId = null, iomChannels = false, isIntranet = false, isExtranet = false): Observable<any> {
    let data: any = {};
    if (!!offset) {
      data["offset"] = offset;
    }
    if (!!limit) {
      data["limit"] = limit;
    }
    if (getOnlyPublic) {
      data["is_public"] = 1;
    }
    if (getOnlyPrivate) {
      data["is_public"] = 0;
    }
    if (getOnlySubscribed) {
      data["member"] = 1;
    }

    if (getOnlyFavorites) {
      data["favorited"] = 1;
    }

    if (!includeArchived) {
      data["status"] = 1;
    }

    if (getOnlyMyChannels && userId) {
      data["author_id"] = userId;
    }

    if (iomChannels) {
      data["is_iom"] = 1;
    }

    if(isIntranet) {
      data["intranet"] = 1;
    }

    if(isExtranet) {
      data["extranet"] = 1;
    }

    return this.middlewareService.getTest(`api/channels/all`, true, data);
  }


  searchAuthorForAChannelTopic(channelID: string, querry: string) {
    if (channelID && querry) {
      return this.middlewareService.getTest(`api/channels/authors${channelID}&&${querry}`, true);
    }
  }

  addComment(body:  { comments: string,  parent_comment_id?: number}, topicId: string, channel_id?: string) {
    topicId = String(topicId)?.replace("iom-", "");
    let data = {
      "comment": body,
      channel_id
    };
    return this.middlewareService.post(`/api/topics/${topicId}/comments`, true, data);
  }

  updateComment(body: string, commentId: number, attachments: any[] = [], channel_id?: string) {
    let data = {
      "comment": {
        comments: body,
        uploads: { ...attachments }
      },
      channel_id
    };
    return this.middlewareService.put(`/api/comments/${commentId}`, true, data);
  }

  openChannel(channelId) {
    return this.middlewareService.getTest(`api/channels/${channelId}`, true);
  }

  loadUnsplashImg(query: string) {
    return this.middlewareService.get(`/api/unsplash/images/search`, true, {query});
  }

  searchChannel(searchString: string, searchInTags = false, tagIds?: number[], data?: { offset: number, limit: number }) {
    if (searchInTags) {
      const tagIdsString = tagIds.toString();
      return this.middlewareService.getTest(`api/channels/search-channels`, true, { tags: tagIdsString, ...(data || {}) });

    } else {
      searchString = "~" + searchString;
      return this.middlewareService.getTest(`api/channels/search-channels`, true, { q: searchString, ...(data || {}) });
    }
  }

  searchChannelViaSolr(searchString: string, searchInTags = false, tagIds?: number[], data?: { offset: number, limit: number }, type: string = "channel") {
    const currentUser = JSON.parse(localStorage.getItem("userConfigFromServer"));
    if (searchInTags) {
      const tagIdsString = tagIds.toString();
      return this.middlewareService.post(`/api/channels/solr-search`, true, { tags: tagIdsString, ...(data || {}) });
    } else {
      searchString = "~" + searchString;
      return this.middlewareService.post(`/api/channels/solr-search`, true, { q:searchString, ...(data || {})  , searchType : type});
    }
  }


  searchIOMChannel(searchString: string, searchInTags = false, tagIds?: number[], data?: { page: number, limit: number }) {
    if (searchInTags) {
      const tagIdsString = tagIds.toString();
      return this.middlewareService.getTest(`api/channels/iom/search-channels`, true, { tags: tagIdsString, ...(data || {}) });
    } else {
      searchString = "~" + searchString;
      return this.middlewareService.getTest(`api/channels/iom/search-channels`, true, { q: searchString, ...(data || {}) });
    }
  }

  searchTopics(searchString: string, searchInTags = false, tagIds?: number[], data?: { offset: number, limit: number }) {
    this.searchTopicsViaSolr(searchString, searchInTags, tagIds, data);
    if (searchInTags) {
      const tagIdsString = tagIds.toString();
      return this.middlewareService.get(`/api/topics/search-topics`, true, { tags: tagIdsString, ...(data || {}) });
    } else {
      searchString = "~" + searchString;
      return this.middlewareService.get(`/api/topics/search-topics`, true, { q: searchString, ...(data || {}) });
    }
  }

  searchTopicsViaSolr(searchString: string, searchInTags = false, tagIds?: number[], data?: { offset: number, limit: number },  type: string = "topic") {
    if (searchInTags) {
      const tagIdsString = tagIds.toString();
      return this.middlewareService.post(`/api/channels/topics/solr-search`, true, { tags: tagIdsString, ...(data || {}), searchType : "topic" });
    } else {
      searchString = "~" + searchString;
      return this.middlewareService.post(`/api/channels/topics/solr-search`, true, { q: searchString, ...(data || {}), searchType : "topic" });
    }
  }


  searchTopicsWithSubject(searchString: string, data?: { offset: number, limit: number }) {
    if (searchString) {
      searchString = "~" + searchString;
      return this.middlewareService.get(`/api/topics`, true, { subject: searchString, ...(data || {}) });
    } else {
      return this.middlewareService.get(`/api/topics`, true, { ...(data || {}) });
    }
  }

  getComments(topicId, channel_id?: string, data?: { offset: number, limit: number, channel_id?: string }) {
    topicId = String(topicId)?.replace("iom-", "");
    if (channel_id) data = { ...data, channel_id };
    return this.middlewareService.getTest(`api/topics/${topicId}/comments`, true, data || {});
  }

  getCommentsWithMentionedComment(topicId, mentionedCommentId, channel_id?: string): Observable<CommentResponse> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.get(`/api/topics/${topicId}/comments/${mentionedCommentId}`, true, {channel_id} );
  }

  getRepliesComments(topicId, commentId, channel_id?: string, data?: { offset: number, limit: number, channel_id?: string }) {
    topicId = String(topicId)?.replace("iom-", "");
    if (channel_id) data = { ...data, channel_id };
    return this.middlewareService.get(`/api/topics/${topicId}/comments?parent_comment_id=${commentId}`, true, data || {});
  }

  likeComment(commentId, channel_id?: string): Observable<{comment: Comment}> {
    return this.middlewareService.post(`/api/comments/${commentId}/like`, true, {channel_id});
  }

  unlikeComment(commentId, channel_id?: string): Observable<{comment: Comment}> {
    return this.middlewareService.post(`/api/comments/${commentId}/unlike`, true, {channel_id});
  }

  dislikeComment(commentId, channel_id?: string): Observable<{comment: Comment}> {
    return this.middlewareService.post(`/api/comments/${commentId}/dislike`, true, {channel_id});
  }

  undislikeComment(commentId, channel_id?: string): Observable<{comment: Comment}> {
    return this.middlewareService.post(`/api/comments/${commentId}/undislike`, true, {channel_id});
  }

  getMembers(id, data?: { offset: number, limit: number }, isSocial: boolean = false) {
    if (isSocial) {
      return this.middlewareService.getTest(`api/channels/${id}/active_members`, true, data);
    }
    return this.middlewareService.getTest(`api/channels/${id}/members`, true, data);
  }

  getConnectedDomains(id) {
    return this.middlewareService.getTest(`api/channels/iom/connected_domains`, true);
  }

  removeChannelMembers(id, participants) {
    return this.middlewareService.post(`/api/channels/remove_members/${id}`, true, {participants});
  }

  updateChannelMembers(id, participants) {
    return this.middlewareService.post(`/api/channels/update_members/${id}`, true, {participants});
  }

  addChannelMembers(id, participants) {
    return this.middlewareService.post(`api/channels/add-members/${id}`, true, {participants});
  }

  getChannelFiles(id, data?: { offset: number, limit: number, q: string, sort: FileGrouping }): Observable<any> {
    if (!data?.q?.length) { delete data.q; }
    return this.middlewareService.getTest<{files: File[]}>(`api/channels/${id}/files`, true, data || {});
  }

  getTopicFiles(id, data?: { offset: number, limit: number, q: string, sort: FileGrouping, channel_id?: string }): Observable<any> {
    // id = id.replace("iom-", "");
    if (!data?.q?.length) { delete data.q; }
    if (!data?.channel_id) { delete data.channel_id; }
    return this.middlewareService.getTest(`api/topics/${id}/files`, true, data || {});
  }

  getTopicFilesSideBar(id, data?: { offset: number, limit: number, q: string, sort: FileGrouping, channel_id?: string }): Observable<any> {
    // id = id.replace("iom-", "");
    if (!data?.q?.length) { delete data.q; }
    if (data?.channel_id) { delete data.channel_id; }
    return this.middlewareService.getTest(`api/topics/${id}/files`, true, data || {});
  }

  getTopicMembers(topicId: Topic["id"], data?: { offset: number, limit: number, channel_id?: string }): Observable<any> {
    topicId = String(topicId)?.replace("iom-", "");
    if (!data.channel_id) { delete data.channel_id; }
    if(!topicId) return;
    return this.middlewareService.getTest(`api/topics/members/${topicId}`, true, data || {});
  }

  addTopicMembers(topicId: Topic["id"], participants): Observable<any> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/${topicId}/add_members`, true, {participants} || {});
  }

  removeTopicMembers(topicId: Topic["id"], participants): Observable<any> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/${topicId}/remove_members`, true, {participants});
  }

  deleteChannel(channelId, moveToTrash = false) {
    let url = `/api/channels/delete/${channelId}`;
    if (moveToTrash) {
      url += `?trash=1`;
    }
    return this.middlewareService.delete(url, true);
  }

  restoreChannel(channelId): Observable<{channel: Channel}> {
    return this.middlewareService.post(`/api/channels/${channelId}/restore`, true, {});
  }

  deleteComment(commentId, channel_id?: string) {
    return this.middlewareService.delete(`/api/topics/comments/delete/${commentId}`, true, {channel_id});
  }

  subscribeChannel(channelId, iom: boolean = false): Observable<{channel: Channel}> {
    if (iom) {
      return this.middlewareService.post(`/api/channels/iom/${channelId}/subscribe`, true, {});
    }
    else {
      return this.middlewareService.post(`/api/channels/subscribe/${channelId}`, true, {});
    }
  }

  unsubscribeChannel(channelId): Observable<{channel: Channel}> {
    return this.middlewareService.post(`/api/channels/unsubscribe/${channelId}`, true, {});
  }

  muteChannel(channelId): Observable<{channel: Channel}> {
    return this.middlewareService.post(`/api/channels/mute/${channelId}`, true, {});
  }

  unmuteChannel(channelId): Observable<{channel: Channel}> {
    return this.middlewareService.post(`/api/channels/unmute/${channelId}`, true, {});
  }

  pinChannel(channelId): Observable<{channel: Channel}> {
    return this.middlewareService.post(`api/channels/pin/${channelId}`, true, {});
  }

  unpinChannel(channelId): Observable<{channel: Channel}> {
    return this.middlewareService.post(`/api/channels/unpin/${channelId}`, true, {});
  }

  archiveChannel(channelId): Observable<any> {
    return this.middlewareService.post(`/api/channels/archive/${channelId}`, true, {});
  }

  favoriteChannel(channelId): Observable<any> {
    return this.middlewareService.post(`/api/channels/favorite/${channelId}`, true, {});
  }

  unfavoriteChannel(channelId): Observable<any> {
    return this.middlewareService.post(`/api/channels/unfavorite/${channelId}`, true, {});
  }

  unarchiveChannel(channelId, unArchiveChildTopics = false): Observable<any> {
    let qp = "";
    if (unArchiveChildTopics) {
      qp += "?unarchive_topics=1";
    }
    return this.middlewareService.post(`/api/channels/unarchive/${channelId}${qp}`, true, {});
  }

  getChannels(offset?: number, limit?: number, getOnlyPublic = false, getOnlyPrivate = false, includeArchived = false, getOnlySubscribed = false, getOnlyFavorites = false, getOnlyMyChannels = false, userId = null, iomChannels = false, isIntranet = false, isExtranet = false): Observable<any> {
    let data: any = {};
    if (!!offset) {
      data["offset"] = offset;
    }
    if (!!limit) {
      data["limit"] = limit;
    }
    if (getOnlyPublic) {
      data["is_public"] = 1;
    }
    if (getOnlyPrivate) {
      data["is_public"] = 0;
    }
    if (getOnlySubscribed) {
      data["member"] = 1;
    }

    if (getOnlyFavorites) {
      data["favorited"] = 1;
    }

    if (!includeArchived) {
      data["status"] = 1;
    }

    if (getOnlyMyChannels && userId) {
      data["author_id"] = userId;
    }

    if (iomChannels) {
      data["is_iom"] = 1;
    }

    if(isIntranet) {
      data["intranet"] = 1;
    }

    if(isExtranet) {
      data["extranet"] = 1;
    }
    let i = 0;
    i = i + 1;
    return this.middlewareService.getTest(`api/channels/all`, true, data);
  }

  getArchivedChannels(data?: { offset: number, limit: number, q: string, sort: ChannelGroupType }): Observable<any> {
    if (!data?.q?.length) {
      delete data.q;
    } else {
      data.q = "~" + data.q;
    }
    return this.middlewareService.get(`/api/channels/all`, true, { ...(data || {}), status: 5,archived:true});
  }

  getTrashedChannels(data?: { offset: number, limit: number, q: string, sort: ChannelGroupType }): Observable<any> {
    if (!data?.q?.length) {
      delete data.q;
    } else {
      data.q = "~" + data.q;
    }
    return this.middlewareService.get(`/api/channels/all`, true, { ...(data || {}), deleted: 1});
  }

  getChannelById(id) {
    return this.middlewareService.getTest(`api/channels/channelById/${id}`, true);
  }

  getSubchannelsByUID(parentUID: number) {
    return this.middlewareService.getTest(`api/channels/subchannels/${parentUID}`, true);
  }

  getArchivedSubchannelsByUID(parentUID: number) {
    return this.middlewareService.getTest(`api/channels/subchannels/${parentUID}`, true, { archived: true });
  }

  getIOMChannelById(id) {
    return this.middlewareService.get(`/api/channels/iom/${id}`, true);
  }

  searchChannels(keyword, offset?: number) {
    let data = {name: keyword};
    if (!!offset) {
      data["offset"] = offset;
    }
    return this.middlewareService.getTest(`api/channels/search-channels`, true, data);
  }

  updateChannel(channelId, body) {
    let data = { "channel" : body };
    return this.middlewareService.put(`/api/channels/update/${channelId}`, true, data);
  }

  createTopic(channelId, body, participants?: any, relatedTopicIds?: any[]) {
    let data: any = { "topic" : body, participants, "related_topic_ids": relatedTopicIds };
    return this.middlewareService.postTest(`api/topics/${channelId}/create-topic`, true, data);
  }

  updateTopic(topicId, body, relatedTopicIds?: any[],journalId?:number,conflictResolution?:any): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    let data = { "topic" : body, "related_topic_ids": relatedTopicIds ,"last_journal_id":journalId,conflict_resolution:conflictResolution};
    return this.middlewareService.put(`api/topics/update/${topicId}`, true, data);
  }

  updateTopicRelations(topicId, relatedTopicIds: any[]) {
    topicId = String(topicId)?.replace("iom-", "");
    let data = {related_topic_ids: relatedTopicIds };
    return this.middlewareService.put(`api/topics/update/${topicId}`, true, data);
  }

  getTopics(channelId: string, offset?: number, limit?: number, query?: string): Observable<any> {
    let data: any = {};
    if (!!offset) {
      data["offset"] = offset;
    }
    if (!!limit) {
      data["limit"] = limit;
    }
    let reqUrl = `api/topics/${channelId}/all`;
    if (!!query) {
      reqUrl += `?${query}`;
    }
    return this.middlewareService.getTest(reqUrl, true, data);
  }

  getUnpublishedTopics(channelId: string): Observable<any> {
    return this.middlewareService.getTest(`api/topics/${channelId}/unpublished`, true, { unpublished: 1});
  }

  getAllTopics(data?: { offset: number, limit: number }): Observable<any> {
    return this.middlewareService.get(`/api/topics`, true, { ...(data || {}) });
  }

  getArchivedTopics(data?: { offset: number, limit: number, q: string, sort: TopicGroupType }): Observable<any> {
    if (!data?.q?.length) {
      delete data.q;
    } else {
      data.q = "~" + data.q;
    }
    return this.middlewareService.get(`/api/topics/trashed-topics`, true, { ...(data || {}), status_id: "c",archived:true});
  }


  getContacts(queryParams: any): Observable<any> {
    let data = {};
    if (queryParams) {
      data = queryParams;
    }
    return this.middlewareService.get(`api/contacts.json`, true, data);
  }

  getTrashedTopics(data?: { offset: number, limit: number, q: string, sort: TopicGroupType }): Observable<any> {
    if (!data?.q?.length) {
      delete data.q;
    } else {
      data.q = "~" + data.q;
    }
    return this.middlewareService.get(`/api/topics/trashed-topics`, true, { ...(data || {}), deleted: 1});
  }

  getTopicDetails(topicId, channel_id: string | null = null) {
    const iom = String(topicId)?.includes("iom");
    topicId = String(topicId)?.replace("iom-", "");
    if (iom && channel_id) {
      return this.middlewareService.get(`/api/topics/${topicId}/${channel_id}`, true);
    }
    else {
      return this.middlewareService.getTest(`api/topics/${topicId}/detail`, true);
    }
  }

  deleteTopicAttachments(ids: any[]) {
    return this.middlewareService.delete(`/api/topics/topic-attachments/delete?ids[]=` + ids.join("&ids[]="), true, {});
  }

  subscribeTopic(topicId): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/subscribe/${topicId}`, true, {});
  }

  unsubscribeTopic(topicId): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/unsubscribe/${topicId}`, true, {});
  }

  uploadFile(body: any, filename: any, content_type: any, showProgress = false): Observable<any> {
    filename = encodeURIComponent(filename);
    let vncDirectoryPublicUrl = "https://vncdirectory.dev.vnc.de";
    let uploadFileHeader = new HttpHeaders({
      "Content-Type": "application/octet-stream",
    });
    if (!!vncDirectoryPublicUrl) {
      if (!vncDirectoryPublicUrl.endsWith("/")) {
        vncDirectoryPublicUrl = vncDirectoryPublicUrl + "/";
      }
      const jwtToken = localStorage.getItem("jwtTemp");
      const apiResponse = this.middlewareService.postWithoutBaseUrl(
        `${vncDirectoryPublicUrl}uploads.json?filename=${filename}&content_type=${content_type}&jwt=${jwtToken}`,
        false, body, uploadFileHeader, false);
      if (!!showProgress) {
        return apiResponse;
      } else {
        return apiResponse.pipe(
          filter( (v: any) => {
            return !!CommonUtil.isHttpResponse(v);
          }),
          map((v: any) => v.body),
        );
      }
    } else {
      if (this.isCordovaOrElectron) {
        uploadFileHeader = uploadFileHeader.set("Authorization", localStorage.getItem("jwtTemp"));
      }
      return this.middlewareService.post(`/api/uploads?filename=${filename}&content_type=${content_type}`, false, body, uploadFileHeader);
    }
  }

  bulkUpdateFiles(attachments: any): Observable<{files: Attachment[]}> {
    let data = {
        attachments
    };
    return this.middlewareService.post(`/api/attachments/bulk_update`, true, data);
  }

  updateSolrIndexes(operationOn: string, id: number): Observable<any> {
    return this.middlewareService.getTest(`api/update-solr-indexes/?operationOn=${operationOn}&id=${id}`, true);
  }

  uploadImageWithPublicUrl(file_url: any): Observable<{upload: Attachment}> {
    let data = {
      file_url
    };
    return this.middlewareService.post(`/api/attachments/upload_from_url`, true, data);
  }


  deleteTopic(topicId, moveToTrash = false) {
    topicId = String(topicId)?.replace("iom-", "");
    let url = `/api/topics/delete/${topicId}`;
    if (moveToTrash) {
      url += `?trash=1`;
    }
    return this.middlewareService.delete(url, true);
  }

  archiveTopic(topicId) {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/archive/${topicId}`, true, {});
  }

  cloneTopic(topicId): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/${topicId}/clone?copy_files=1&copy_members=1`, true, {});
  }

  unArchiveTopic(topicId, unArchiveParentChannel = false, unArchiveSiblingTopics = false) {
    topicId = String(topicId)?.replace("iom-", "");
    let qp = "";
    if (unArchiveParentChannel) {
      qp += "?unarchive_channel=1";
    }
    if (unArchiveSiblingTopics) {
      qp += "&unarchive_siblings=1";
    }
    return this.middlewareService.post(`/api/topics/unarchive/${topicId}${qp}`, true, {});
  }

  restoreTopic(topicId): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/${topicId}/restore`, true, {});
  }

  readTopic(topicId): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/read/${topicId}`, true, {});
  }

  unreadTopic(topicId): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/unread/${topicId}`, true, {});
  }

  deleteBulkAttachment(ids: any[]) {
    return this.middlewareService.delete(`/api/attachments/bulk/delete?ids[]=` + ids.join("&ids[]="), true, {});
  }

  likesTopic(topicId, channel_id?: string): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/like/${topicId}`, true, { channel_id });
  }

  unlikesTopic(topicId, channel_id?: string): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.post(`/api/topics/unlike/${topicId}`, true, { channel_id });
  }

  getCommentDetails(commentId: Comment["id"]) {
    return this.middlewareService.get(`/api/comments/${commentId}`, true);
  }

  readAllTopicComments(topicId, channel_id?: string): Observable<{ topic: Topic }> {
    topicId = String(topicId)?.replace("iom-", "");
    return this.middlewareService.postTest(`api/topics/${topicId}/read_all_comments`, true, {channel_id});
  }

  addRelationToTopic(topicId, ids: any[]): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    let data: any = { "related_topic_ids" : ids };
    return this.middlewareService.post(`/api/topics/${topicId}/add_relations`, true, data);
  }

  removeRelationToTopic(topicId, ids: any[]): Observable<{topic: Topic}> {
    topicId = String(topicId)?.replace("iom-", "");
    let data: any = { "related_topic_ids" : ids };
    return this.middlewareService.post(`/api/topics/${topicId}/remove_relations`, true, data);
  }

  deleteFile(fileId): Observable<any> {
    return this.middlewareService.delete(`/api/topics/attachments/delete/${fileId}/`, true, {});
  }

  getEnglishJson(): Observable<any> {
    return this.middlewareService.get("assets/i18n/en.json", false, null, null, false);
  }

  getGermanJson(): Observable<any> {
    return this.middlewareService.get("assets/i18n/de.json", false, null, null, false);
  }

  getFrenchJson(): Observable<any> {
    return this.middlewareService.get("assets/i18n/fr.json", false, null, null, false);
  }

  createInvitations(userJids: string[], channelId: string, topicId?: string): Observable<{ invitations: Invitation[] }> {
    let data = {
      "channel_id": channelId,
      "user_jids": userJids
    };

    if (!!topicId) {
      topicId = String(topicId)?.replace("iom-", "");
      data["topic_id"] = topicId;
    }
    return this.middlewareService.post(`/api/invitations`, true, data);
  }

  getInvitationDetails(invitationToken: string): Observable<{ invitation: Invitation }> {
    return this.middlewareService.get(`/api/invitations/${invitationToken}`, true);
  }

  accessRequest(userJids: string[], channelId: string, topicId?: string): Observable<{ invitation: Invitation }> {
    let data = {
      "channel_id": channelId,
      "user_jids": userJids
    };

    if (!!topicId) {
      topicId = String(topicId)?.replace("iom-", "");
      data["topic_id"] = topicId;
    }
    return this.middlewareService.post(`/api/invitations/access_request`, true, data);
  }

  acceptInvitations(invitationToken: string): Observable<{ invitation: Invitation }> {
    return this.middlewareService.post(`/api/invitations/${invitationToken}/accept`, true, {});
  }

  approveInvitations(invitationToken: string): Observable<{ invitation: Invitation }> {
    return this.middlewareService.post(`/api/invitations/${invitationToken}/approve`, true, {});
  }

  declineInvitations(invitationToken: string): Observable<{ invitation: Invitation }> {
    return this.middlewareService.post(`/api/invitations/${invitationToken}/decline`, true, {});
  }

  deleteInvitations(invitationToken: string): Observable<any> {
    return this.middlewareService.delete(`/api/invitations/${invitationToken}`, true, {});
  }

  getReceivedInvitations(channelId?: string, topicId?: string, offset?: number, limit?: number): Observable<{ invitations: Invitation[] }> {
    let data: any = {
      received: 1
    };
    if (!!channelId) {
      data["channel_id"] = channelId;
    }
    if (!!topicId) {
      topicId = String(topicId)?.replace("iom-", "");
      data["topic_id"] = topicId;
    }
    if (!!offset) {
      data["offset"] = offset;
    }
    if (!!limit) {
      data["limit"] = limit;
    }
    return this.middlewareService.get(`/api/invitations`, true, data);
  }

  emailFilesToFriend(data: {emails: string[], subject: string, body: string, attachment_ids: number[] }) {
    return this.middlewareService.post(`/api/attachments/share_by_email`, true, data );
  }

  getSentInvitations(channelId: string, topicId?: string, offset?: number, limit?: number): Observable<{ invitations: Invitation[] }> {
    let data: any = {};
    if (!!channelId) {
      data["channel_id"] = channelId;
    }
    if (!!topicId) {
      topicId = String(topicId)?.replace("iom-", "");
      data["topic_id"] = topicId;
    }
    if (!!offset) {
      data["offset"] = offset;
    }
    if (!!limit) {
      data["limit"] = limit;
    }
    return this.middlewareService.getTest(`api/channels/invitations`, true, data);
  }

  getDefaultCovers() {
    return this.middlewareService.getTest(`api/topics/default-avatars`, true);
  }


  renameFile(fileId: string, name: string): Observable<any> {
    return this.middlewareService.post(`/api/attachments/bulk_update`, true, {"attachments": {[fileId + ""]: {filename: name}}});
  }

  getNotifications(offset?: number, limit?: number, unread = false): Observable<any> {
    // console.warn("[channels.service] getNotifications ", unread);
    let data: any = {};
    if (!!offset) {
      data["offset"] = offset;
    }
    if (!!limit) {
      data["limit"] = limit;
    }
    if (!!unread) {
      data["unread"] = true;
    }
    return this.middlewareService.getTest(`api/notifications/get-all`, true, data);
  }

  getUnreadNotificationCount(): Observable<any> {
    return this.middlewareService.get(`/api/notifications/get-unread`, true);
  }

  markAsReadNotification(notificationId): Observable<any> {
    return this.middlewareService.post(`/api/notifications/${notificationId}/read`, true, {});
  }

  globalSearch(data): Observable<any> {
    return this.middlewareService.post(`/api/search`, true, data);
  }

  markAsReadAllNotification(): Observable<any> {
    return this.middlewareService.post(`/api/notifications/all/read`, true, {});
  }

  markAsReadNotificationByPeriod(pariodName: string): Observable<any> {
    return this.middlewareService.post(`/api/notifications/${pariodName}/read`, true, {});
  }

  getSubscribeTopics(userId, offset, limit, query): Observable<any> {
    if (query === "") {
      return this.middlewareService.getTest(`api/topics/subscribed/subscribe-topics?userid=` + userId + "&offset=" + offset + "&limit=" + limit , true, {});
    } else {
      return this.searchTopics(query, false, [], { offset: offset, limit: limit });
    }
  }

  getBulkActionPermisions(topicIDs: any) {
    let body = { "topicID": topicIDs };
    return this.middlewareService.post(`/api/topics/bulk_actions`, true, body);
  }

  performBulkAction(body: any) {
    return this.middlewareService.post(`api/topics/perfrom_bulk_action`, true, body);
  }

  getTopicFilters(queryClass: string) {
   return this.middlewareService.getTest(`api/topics/querries/filters?query_class=${queryClass}`, true);
  }

  getTopicByWord(channelId, searchString) {
    return this.middlewareService.get(`/api/channels/${channelId}/topics?q=~${searchString}`, true);
  }

  getChannelActiveMemberFromSolr(channelId) {
    const body = {
      channelId: channelId
    };
    return this.middlewareService.post(`/api/v1/search/channel/active/members`, true, body);
  }
}
